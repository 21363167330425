/* #active{
  background-color: #00a783;
  color:white;
 
  
} */


.App{
    width: 100%;
    height: 100vh;
    display: flex;
   
    /* background-color:#DDE392 */
}


:root{
  scroll-behavior: smooth;
  --third:#0a3335;
  --second: #00a727;;
  --primary: #0a3335;
  --bgcolor: #f8fafc;
  --black: black;
  --whatsapp: #16a34a;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
}
a{
  text-decoration: none;
  cursor: pointer;
}
li{
  text-decoration: none;
  list-style: none;
}
a:hover{
  
  text-decoration: none;
  
}